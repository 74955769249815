import styles from "../../styles/voltmesh-features.module.scss"
import cblockStyles from "../../styles/commons/cblock.module.scss"
import React, { useState } from "react"
import Layout from "../../components/layout/layout"
import StartDemo from "../../components/start-demo/start-demo"
import ZeroTrustWafGraphAnim from "../../components/animated-graphs/zero-trust-waf/zero-trust-waf-anim"
import { graphql, useStaticQuery } from "gatsby"
import SEO from "../../components/seo"
import withAppContext from "../../contexts/app.context"
import { ENV_CONSOLE_BASE_URL } from "../../utils/secrets"
import UseCaseBenefits from "../../components/use-case-benefit/use-case-benefits"
import ComparisonTable from "../../components/comparison-table/comparison-table"
import ZtWafHeroAnim from "../../components/animated-graphs/voltmesh-ztwaf-hero/voltmesh-ztwaf-hero"
import CascadeVideo from "../../components/cascade-video/cascade-video"
import ArrowSvg from "../../components/svg/arrow"

const VoltMeshAztPage = () => {
  const queryResult = useStaticQuery(graphql`
    {
      seo: markdownRemark(
        fields: {
          relativePath: { eq: "products__voltmesh-azt-mt" }
          category: { eq: "seo" }
        }
      ) {
        frontmatter {
          metaTitle
          metaDescription
          metaKeywordList
          metaImageName
          metaTwImageName
        }
      }
    }
  `)

  const {
    seo: {
      frontmatter: {
        metaTitle,
        metaDescription,
        metaKeywordList,
        metaImageName,
        metaTwImageName,
      },
    },
  } = queryResult

  const [selectedTopFeatIndex, setselectedTopFeatIndex] = useState(1)

  return (
    <Layout>
      <SEO
        title={metaTitle}
        pageUrl={"/products/voltmesh-automated-zero-trust-and-api-security"}
        description={metaDescription}
        keywords={metaKeywordList}
        imageName={!!metaImageName ? `/previews/${metaImageName}` : undefined}
        twImageName={
          !!metaTwImageName ? `/previews/${metaTwImageName}` : undefined
        }
      />

      {/* Hero Block */}
      <section className={styles.header}>
        <div className={styles.header__content}>
          <div className="container volterra-container">
            <div className="row">
              <div className={`col-lg-7`}>
                <h1 className={styles.header__title}>
                  Automated Zero-Trust & API Security
                </h1>
                <a
                  aria-label="Start for Free"
                  href={`${ENV_CONSOLE_BASE_URL}/signup/start`}
                  className={"btn btn-primary " + styles.header__btn}>
                  Start for Free
                </a>
              </div>
            </div>
          </div>
          <div className={styles.header__imgwrapper}>
            <ZtWafHeroAnim />
          </div>
        </div>
      </section>

      {/* Intro */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Discover, Control & Mitigate Threats to APIs Using Machine
              Learning
            </h2>
          </div>
          <div className="row align-items-center">
            <div className="col-lg-6 order-lg-last">
              <div className={styles.textblock}>
                <p className={cblockStyles.cblock__intro}>
                  VoltMesh’s web app firewall (NG-WAF) combines the power of
                  data analytics and deep learning to discover your app APIs and
                  mitigate threats. It analyzes the data collected from VoltMesh
                  distributed load balancers to automatically detect APIs and
                  create authorization policies to simplify zero-trust
                  implementation for DevOps and SecOps.
                </p>
                <p className={cblockStyles.cblock__intro}>
                  Using advanced analytics on the data collected across users on
                  its multi-tenant platform, VoltMesh identifies behavioral
                  anomalies and automatically updates to mitigate threats from
                  users as well as internal apps.
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <ZeroTrustWafGraphAnim />
            </div>
          </div>
        </div>
      </section>

      {/* Comparison Table */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              VoltMesh vs. Traditional WAF
            </h2>
          </div>
          <div className={cblockStyles.cblock__body}>
            <ComparisonTable
              props={{
                cols: ["Features", "Traditional", "VoltMesh"],
                rows: [
                  {
                    name: "API discovery",
                    cells: ["Static (Swagger)", "Automated (AI/ML)"],
                  },
                  {
                    name: "Automated service graph",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Zero-day attack mitigation",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Automated API policy",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "User behavior detection",
                    cells: ["-", "Included"],
                  },
                  {
                    name: "Anomaly detection",
                    cells: ["Included", "Included"],
                  },
                  {
                    name: "False positive reduction",
                    cells: ["Included", "Included"],
                  },
                ],
              }}
            />
          </div>
        </div>
      </section>

      {/* Automated Policy for Zero Trust */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-6">
              <div className={styles.textblock}>
                <h2 className={`mb-4 ${styles.headtitle}`}>
                  Automated Policy for Zero-Trust
                </h2>
                <p className={cblockStyles.cblock__intro}>
                  Automate discovery of APIs and create authorization policies
                  without requiring closed loop coordination between developers
                  and ops (DevOps and SecOps). Frictionless implementation of
                  zero-trust using machine learning.
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <img
                className="img-fluid"
                src="/img/products/azt-waf1.png"
                alt="VoltConsole API Explorer screenshot"
              />
            </div>
          </div>

          <UseCaseBenefits
            props={{
              noTitle: true,
              noCards: true,
              noPadding: true,
              imgUrl: "/img/products/",
              content: {
                benefits: [
                  {
                    iconName: "puzzle-icon.svg",
                    title: "Optimize Policy Management",
                    description: `Alleviate challenges with automated policy creation and lifecycle management — reducing a major hurdle and cost for agile development and operations teams`,
                  },
                  {
                    iconName: "compliance-icon.svg",
                    title: "Simplify Compliance & Reporting",
                    description: `Continuous updates of policies + reporting helps achieve compliance (PCI-DSS, SOC, etc) in a dynamic development environment`,
                  },
                  {
                    iconName: "shield-alt2-icon.svg",
                    title: "Zero-Trust for Legacy Software",
                    description: `Automated API discovery mitigates limited developer support for legacy apps or lack of knowledge of APIs in open source software`,
                  },
                  {
                    iconName: "performance-icon.svg",
                    title: "Improve Performance",
                    description: `VoltMesh’s ML uncovers dynamic components in APIs from billions of requests to ensure that policies are compact and execute with minimal overhead`,
                  },
                ],
              },
            }}
          />
        </div>
      </section>

      {/* Anomaly Detection for Threat Mitigation */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__invert}`}>
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-6">
              <div className={styles.textblock}>
                <h2 className={`mb-4 ${styles.headtitle}`}>
                  Anomaly Detection for Threat Mitigation
                </h2>
                <p className={cblockStyles.cblock__intro}>
                  Continuous machine learning (ML) on traffic from users and
                  apps allows VoltMesh to predict, block, and alert on
                  suspicious and abnormal activity. This allows you to augment
                  zero-trust and protect public apps that are not authenticated.
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <img
                className="img-fluid"
                src="/img/products/azt-waf2.png"
                alt="VoltConsole Anomaly Detection analysis screenshot"
              />
            </div>
          </div>

          <UseCaseBenefits
            props={{
              noTitle: true,
              noCards: true,
              noPadding: true,
              imgUrl: "/img/products/",
              content: {
                benefits: [
                  {
                    iconName: "shield-alt2-icon.svg",
                    title: "Prevent Zero-Day Attacks",
                    description: `As it correlates across multiple data points and learns across customers, the ML gets smarter in detecting zero-day and L7 DDoS attacks`,
                  },
                  {
                    iconName: "buoy-icon.svg",
                    title: "Block OWASP Top 10",
                    description: `Our automated engine helps you define the right threat and paranoia-level to protect from OWASP Top-10 attacks like SQL, XSS, XXE, etc`,
                  },
                  {
                    iconName: "laptop-icon.svg",
                    title: "Detect Threats across Sessions",
                    description: `Using behavioral analysis of sessions from individual users and across multiple users, our ML can better detect anomalies and mitigate threats`,
                  },
                  {
                    iconName: "gauge-alt-icon.svg",
                    title: "Reduce False Positives",
                    description: `Reinforcement learning across many users and APIs allows VoltMesh to predict if certain apps experience false positives, improving efficacy`,
                  },
                ],
              },
            }}
          />
        </div>
      </section>

      {/* Visualization & Insights for Forensics */}
      <section className={cblockStyles.cblock}>
        <div className="container">
          <div className="row mb-5">
            <div className="col-lg-6">
              <div className={styles.textblock}>
                <h2 className={`mb-4 ${styles.headtitle}`}>
                  Visualization & Insights for Forensics
                </h2>
                <p className={cblockStyles.cblock__intro}>
                  VoltMesh offloads the problem of collecting, storing,
                  analyzing, and visualizing the logs and metrics needed to
                  understand, mitigate and root-cause threats. It provides
                  visibility on user and app-to-app activity across all your
                  apps — microservices or monolithic.
                </p>
              </div>
            </div>
            <div className="col-lg-6 mt-4 mt-lg-0">
              <img
                className="img-fluid"
                src="/img/products/azt-waf3.jpg"
                alt="VoltConsole Traffic analysis graph screenshot"
              />
            </div>
          </div>

          <UseCaseBenefits
            props={{
              noTitle: true,
              noCards: true,
              noPadding: true,
              imgUrl: "/img/products/",
              content: {
                benefits: [
                  {
                    iconName: "binoculars-icon.svg",
                    title: "App Topology with Service & API Graph",
                    description: `The learning core not only discovers all the APIs in your apps, but it also discovers the service mesh graph based on active data flow`,
                  },
                  {
                    iconName: "gauge-icon.svg",
                    title: "Reduce Time to Resolution",
                    description: `With powerful visualizations and the ability to analyze security events, VoltMesh helps get to root cause quickly and take mitigation actions`,
                  },
                  {
                    iconName: "integrate-icon.svg",
                    title: "Integrate with SIEM & Alerting Tools",
                    description: `Get security events and anomaly detection alerts delivered to your favorite tool like Slack, PagerDuty and email; integrate with Splunk or other SIEMs`,
                  },
                  {
                    iconName: "shield-alt2-icon.svg",
                    title: "24x7 NOC & SOC",
                    description: `Volterra SREs are able to offload your ops team and provide 24x7 monitoring of mission-critical apps and threat mitigation`,
                  },
                ],
              },
            }}
          />
        </div>
      </section>

      {/* Zero-Trust & API Security in Minutes */}
      <section
        className={`${cblockStyles.cblock} ${cblockStyles.cblock__grey}`}>
        <div className={"container"}>
          <div className={cblockStyles.cblock__header}>
            <h2 className={cblockStyles.cblock__head}>
              Zero-Trust & API Security in Minutes
            </h2>
          </div>

          <div className={cblockStyles.cblock__body}>
            <div className={"row " + styles.topfeatsslider}>
              <div className="col-lg-5">
                <div className={styles.topfeatsslider__items}>
                  {/* Item 1 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(1)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 1
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>1</span>
                      <h5>
                        Configure VoltMesh as a Gateway/Proxy
                        <img
                          className={`ml-2 ${styles.topfeatsslider__caret}`}
                          src="/img/products/caret-icon.svg"
                          alt=">"
                        />
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Configure service discovery of your Kubernetes or legacy
                        apps and define routing rules to direct incoming
                        requests to discovered services
                      </p>
                      <a
                        href="/docs/how-to/app-networking/http-load-balancer"
                        className={styles.topfeatsslider__link}
                        aria-label="Multi Cloud Networking">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 1 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: " ",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896841.sd.mp4?s=b6dd238bad7d6709f5094f8a88737603a41180a3&profile_id=165",
                                  videoLength: "31000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 2 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(2)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 2
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>2</span>
                      <h5>
                        Configure API Markup & Control
                        <img
                          className={`ml-2 ${styles.topfeatsslider__caret}`}
                          src="/img/products/caret-icon.svg"
                          alt=">"
                        />
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Create an app_type object and enable machine learning on
                        APIs, API requests, and time-series metrics collected
                        across the system
                      </p>
                      <a
                        href="/docs/how-to/app-security/apiep-discovery-control"
                        className={styles.topfeatsslider__link}
                        aria-label="Multi Cloud Networking">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 2 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: " ",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896885.sd.mp4?s=9424b889acb2957f6320f43ac8c98ef22e600bb2&profile_id=165",
                                  videoLength: "14000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 3 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(3)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 3
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>3</span>
                      <h5>
                        Configure Anomaly <br />
                        Detection
                        <img
                          className={`ml-2 ${styles.topfeatsslider__caret}`}
                          src="/img/products/caret-icon.svg"
                          alt=">"
                        />
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Configure time series analysis on the request and
                        response metrics (latency, rate, error) for all services
                        in your application
                      </p>
                      <a
                        href="/docs/how-to/app-security/tsa-detection"
                        className={styles.topfeatsslider__link}
                        aria-label="Multi Cloud Networking">
                        Read more
                        <ArrowSvg className="arrow" />
                      </a>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 3 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: " ",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896938.sd.mp4?s=e44e9fcef8cbe5b71e7b3947e95f4f6fc786cf26&profile_id=165",
                                  videoLength: "15000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Item 4 */}
                  <div
                    onClick={() => {
                      setselectedTopFeatIndex(4)
                    }}
                    className={
                      styles.topfeatsslider__item +
                      `${
                        selectedTopFeatIndex === 4
                          ? ` ${styles.topfeatsslider__item__active}`
                          : ""
                      }`
                    }>
                    <div className={styles.topfeatsslider__head}>
                      <span className={styles.topfeatsslider__no}>4</span>
                      <h5>
                        Send Requests & Start Mitigating
                        <img
                          className={`ml-2 ${styles.topfeatsslider__caret}`}
                          src="/img/products/caret-icon.svg"
                          alt=">"
                        />
                      </h5>
                    </div>
                    <div className={styles.topfeatsslider__inner}>
                      <p>
                        Send good and malicious requests to the specified
                        service or APIs and let VoltMesh handle discovery of
                        APIs, creation of policies, and mitigation of threats
                      </p>
                      <div className={styles.topfeatsslider__mobilecontent}>
                        {selectedTopFeatIndex === 4 && (
                          <CascadeVideo
                            props={{
                              videos: [
                                {
                                  title: " ",
                                  videoUrl:
                                    "https://player.vimeo.com/external/449896943.sd.mp4?s=2e956741f2c655465d075b499626cbd2140a413f&profile_id=165",
                                  videoLength: "16000",
                                },
                              ],
                            }}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-7">
                <div className={styles.topfeatsslider__img}>
                  {selectedTopFeatIndex === 1 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: " ",
                              videoUrl:
                                "https://player.vimeo.com/external/449896841.sd.mp4?s=b6dd238bad7d6709f5094f8a88737603a41180a3&profile_id=165",
                              videoLength: "31000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 2 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: " ",
                              videoUrl:
                                "https://player.vimeo.com/external/449896885.sd.mp4?s=9424b889acb2957f6320f43ac8c98ef22e600bb2&profile_id=165",
                              videoLength: "14000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 3 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: " ",
                              videoUrl:
                                "https://player.vimeo.com/external/449896938.sd.mp4?s=e44e9fcef8cbe5b71e7b3947e95f4f6fc786cf26&profile_id=165",
                              videoLength: "15000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                  {selectedTopFeatIndex === 4 && (
                    <>
                      <CascadeVideo
                        props={{
                          videos: [
                            {
                              title: " ",
                              videoUrl:
                                "https://player.vimeo.com/external/449896943.sd.mp4?s=2e956741f2c655465d075b499626cbd2140a413f&profile_id=165",
                              videoLength: "16000",
                            },
                          ],
                        }}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <StartDemo
        props={{
          title: "Upgrade Your App Security Now",
          benefits: [
            "API discovery",
            "Automated policies",
            "Anomaly detection",
            "Zero-day mitigation",
          ],
        }}
      />
    </Layout>
  )
}

export default withAppContext(VoltMeshAztPage)
