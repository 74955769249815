import React, { useEffect } from "react"
import styles from "./zero-trust-waf-anim.module.scss"
import anime from "animejs"

const ZeroTrustWafGraphAnim = () => {
  const totalDuration = 2000
  const inboundDuration = 800
  const midDuration = 1000
  const outDuration = 800
  const dashAnimDuration = 500
  const fadeinOutDuration = 1000
  const tagDuration = 600
  const tagPretime = "-=200"
  const lineOpacity = 0.8
  const dashFadeIn = 0.7
  const lineFadeOut = 0.4

  let maintl
  let dashestl

  let maintlMob
  let dashestlMob

  const startDashes = () => {
    dashestl.play()
  }

  const startDashesMob = () => {
    dashestlMob.play()
  }

  useEffect(() => {
    maintl = anime.timeline({
      easing: "easeInOutQuad",
      duration: totalDuration,
      autoplay: true,
    })

    dashestl = anime.timeline({
      easing: "linear",
      duration: dashAnimDuration,
      loop: true,
      autoplay: false,
    })

    maintlMob = anime.timeline({
      easing: "easeInOutQuad",
      duration: totalDuration,
      autoplay: true,
    })

    dashestlMob = anime.timeline({
      easing: "linear",
      duration: dashAnimDuration,
      loop: true,
      autoplay: false,
    })

    maintl
      .add({
        targets: "#img",
        duration: 900,
        opacity: [0, 1],
        translateY: [-8, 1],
      })
      .add(
        {
          targets: "#top1",
          duration: inboundDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [145, 0],
        },
        "-=400"
      )
      .add(
        {
          targets: "#login-post",
          duration: tagDuration,
          opacity: [0, 1],
          // translateY: [67, 71],
          // translateX: [62, 62]
        },
        tagPretime
      )
      .add(
        {
          targets: "#top2",
          duration: inboundDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [217, 0],
        },
        "-=400"
      )
      .add({
        targets: "#mid1",
        duration: midDuration,
        opacity: [0, lineOpacity],
        strokeDashoffset: [240, 0],
      })
      .add(
        {
          targets: "#stop",
          duration: tagDuration,
          opacity: [0, 1],
        },
        "-=100"
      )
      .add(
        {
          targets: "#catalog-post",
          duration: tagDuration,
          opacity: [0, 1],
        },
        tagPretime
      )
      .add({
        targets: "#bottom1",
        duration: outDuration,
        opacity: [0, lineOpacity],
        strokeDashoffset: [145, 0],
      })
      .add(
        {
          targets: "#checkout-post",
          duration: tagDuration,
          opacity: [0, 1],
        },
        tagPretime
      )
      .add(
        {
          targets: "#bottom2",
          duration: outDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [117, 0],
        },
        "-=500"
      )
      .add(
        {
          targets: "#currency-post",
          duration: tagDuration,
          opacity: [0, 1],
        },
        tagPretime
      )
      .add(
        {
          targets: "#bottom3",
          duration: outDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [122, 0],
        },
        "-=500"
      )
      .add(
        {
          targets: "#bottom4",
          duration: outDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [122, 0],
          complete: startDashes,
        },
        "-=800"
      )
      .add(
        {
          targets: "#adservice-get",
          duration: tagDuration,
          opacity: [0, 1],
        },
        tagPretime
      )
      .add(
        {
          targets: "#reviews-post",
          duration: tagDuration,
          opacity: [0, 1],
        },
        "-=500"
      )
      .add({
        targets: [
          "#top1",
          "#top2",
          "#mid1",
          "#bottom1",
          "#bottom2",
          "#bottom3",
          "#bottom4",
          "#bottom5",
        ],
        duration: fadeinOutDuration,
        opacity: lineFadeOut,
      })
      .add(
        {
          targets: [
            "#top1-dash",
            "#top2-dash",
            "#mid1-dash",
            "#bottom1-dash",
            "#bottom2-dash",
            "#bottom3-dash",
            "#bottom4-dash",
            "#bottom5-dash",
          ],
          duration: fadeinOutDuration,
          opacity: [0, dashFadeIn],
        },
        "-=500"
      )

    dashestl.add({
      targets: [
        "#top1-dash",
        "#top2-dash",
        "#mid1-dash",
        "#bottom1-dash",
        "#bottom2-dash",
        "#bottom3-dash",
        "#bottom4-dash",
        "#bottom5-dash",
      ],
      strokeDashoffset: [7, 0],
    })

    maintlMob
      .add({
        targets: "#img",
        duration: 900,
        opacity: [0, 1],
        translateY: [-8, 1],
      })
      .add(
        {
          targets: "#mtop1",
          duration: inboundDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [145, 0],
        },
        "-=400"
      )
      .add(
        {
          targets: "#mlogin-post",
          duration: tagDuration,
          opacity: [0, 1],
          // translateY: [67, 71],
          // translateX: [62, 62]
        },
        tagPretime
      )
      .add(
        {
          targets: "#mtop2",
          duration: inboundDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [217, 0],
        },
        "-=400"
      )
      .add({
        targets: "#mmid1",
        duration: midDuration,
        opacity: [0, lineOpacity],
        strokeDashoffset: [240, 0],
      })
      .add(
        {
          targets: "#mstop",
          duration: tagDuration,
          opacity: [0, 1],
        },
        "-=100"
      )
      .add(
        {
          targets: "#mcatalog-post",
          duration: tagDuration,
          opacity: [0, 1],
        },
        tagPretime
      )
      .add({
        targets: "#mbottom1",
        duration: outDuration,
        opacity: [0, lineOpacity],
        strokeDashoffset: [145, 0],
      })
      .add(
        {
          targets: "#mcheckout-post",
          duration: tagDuration,
          opacity: [0, 1],
        },
        tagPretime
      )
      .add(
        {
          targets: "#mbottom2",
          duration: outDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [117, 0],
        },
        "-=500"
      )
      .add(
        {
          targets: "#mcurrency-post",
          duration: tagDuration,
          opacity: [0, 1],
        },
        tagPretime
      )
      .add(
        {
          targets: "#mbottom3",
          duration: outDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [122, 0],
        },
        "-=500"
      )
      .add(
        {
          targets: "#mbottom4",
          duration: outDuration,
          opacity: [0, lineOpacity],
          strokeDashoffset: [122, 0],
          complete: startDashesMob,
        },
        "-=800"
      )
      .add(
        {
          targets: "#madservice-get",
          duration: tagDuration,
          opacity: [0, 1],
        },
        tagPretime
      )
      .add(
        {
          targets: "#mreviews-post",
          duration: tagDuration,
          opacity: [0, 1],
        },
        "-=500"
      )
      .add({
        targets: [
          "#mtop1",
          "#mtop2",
          "#mmid1",
          "#mbottom1",
          "#mbottom2",
          "#mbottom3",
          "#mbottom4",
          "#mbottom5",
        ],
        duration: fadeinOutDuration,
        opacity: lineFadeOut,
      })
      .add(
        {
          targets: [
            "#mtop1-dash",
            "#mtop2-dash",
            "#mmid1-dash",
            "#mbottom1-dash",
            "#mbottom2-dash",
            "#mbottom3-dash",
            "#mbottom4-dash",
            "#mbottom5-dash",
          ],
          duration: fadeinOutDuration,
          opacity: [0, dashFadeIn],
        },
        "-=500"
      )

    dashestlMob.add({
      targets: [
        "#mtop1-dash",
        "#mtop2-dash",
        "#mmid1-dash",
        "#mbottom1-dash",
        "#mbottom2-dash",
        "#mbottom3-dash",
        "#mbottom4-dash",
        "#mbottom5-dash",
      ],
      strokeDashoffset: [7, 0],
    })
  }, [])

  return (
    <div className={styles.anim}>
      <div className={styles.anim__mobile}>
        <img
          id="img"
          className={styles.anim__img}
          src="/img/products/voltmesh-azt-graph-app-firewall-mobile.png"
          alt="Zero-trust Voltmesh WAF graph"
        />
        <svg
          className={styles.anim__svg}
          width="384px"
          height="570px"
          viewBox="0 0 384 570"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <g id="Artboard" transform="translate(-710.000000, -1697.000000)">
              <g
                id="Group-4-Copy"
                transform="translate(710.000000, 1697.000000)">
                <rect id="canvas" x="0" y="0" width="384" height="570"></rect>
                <path
                  d="M101.5,491.31118 L104.5,491.31118 C121.620827,491.31118 135.5,477.432008 135.5,460.31118 L135.5,417.31118 C135.5,401.294923 148.483742,388.31118 164.5,388.31118 L169.5,388.31118 L169.5,388.31118"
                  id="mbottom4"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="150"
                  transform="translate(135.500000, 439.811180) scale(-1, 1) rotate(-270.000000) translate(-135.500000, -439.811180) "></path>
                <path
                  d="M216.5,390.31118 L221.13939,390.31118 C237.155648,390.31118 250.13939,403.294923 250.13939,419.31118 L250.13939,459.31118 C250.13939,475.879723 263.570848,489.31118 280.13939,489.31118 L284.5,489.31118 L284.5,489.31118"
                  id="mbottom3"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="150"
                  transform="translate(250.500000, 439.811180) scale(-1, 1) rotate(-270.000000) translate(-250.500000, -439.811180) "></path>
                <path
                  d="M215.15559,322.15559 L216.064931,322.15559 C233.185758,322.15559 247.064931,308.276417 247.064931,291.15559 L247.064931,252.15559 C247.064931,236.139332 260.048673,223.15559 276.064931,223.15559 L285.84441,223.15559 L285.84441,223.15559"
                  id="mbottom2"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="147"
                  transform="translate(250.500000, 272.655590) scale(-1, 1) rotate(-270.000000) translate(-250.500000, -272.655590) "></path>
                <path
                  d="M63.5,131.5 L87.5674301,131.5 C104.135973,131.5 117.56743,118.068542 117.56743,101.5 L117.56743,77.5 C117.56743,60.9314575 130.998888,47.5 147.56743,47.5 L170.5,47.5 L170.5,47.5"
                  id="mtop1"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="168"
                  transform="translate(117.000000, 89.500000) scale(-1, 1) rotate(-270.000000) translate(-117.000000, -89.500000) "></path>
                <path
                  d="M204.5,131.5 L228.56743,131.5 C245.135973,131.5 258.56743,118.068542 258.56743,101.5 L258.56743,77.5 C258.56743,60.9314575 271.998888,47.5 288.56743,47.5 L311.5,47.5 L311.5,47.5"
                  id="mbottom1"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="168"
                  transform="translate(258.000000, 89.500000) scale(-1, -1) rotate(-90.000000) translate(-258.000000, -89.500000) "></path>
                <path
                  d="M51.2186648,152.781335 L93.08114,152.781335 C99.6508716,152.781335 105.671949,156.445913 108.690097,162.281335 C111.708245,168.116758 117.729323,171.781335 124.299054,171.781335 L303.781335,171.781335 L303.781335,171.781335"
                  id="mmid1"
                  stroke="#F94627"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="261"
                  transform="translate(177.500000, 162.281335) scale(-1, 1) rotate(-270.000000) translate(-177.500000, -162.281335) "></path>
                <line
                  x1="74.5"
                  y1="237.220521"
                  x2="74.5"
                  y2="474.220521"
                  id="mtop2"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="238"
                  transform="translate(74.500000, 355.720521) scale(-1, 1) translate(-74.500000, -355.720521) "></line>
                <path
                  d="M101.5,491.31118 L104.5,491.31118 C121.620827,491.31118 135.5,477.432008 135.5,460.31118 L135.5,417.31118 C135.5,401.294923 148.483742,388.31118 164.5,388.31118 L169.5,388.31118 L169.5,388.31118"
                  id="mbottom4-dash"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"
                  transform="translate(135.500000, 439.811180) scale(-1, 1) rotate(-270.000000) translate(-135.500000, -439.811180) "></path>
                <path
                  d="M216.5,390.31118 L221.13939,390.31118 C237.155648,390.31118 250.13939,403.294923 250.13939,419.31118 L250.13939,459.31118 C250.13939,475.879723 263.570848,489.31118 280.13939,489.31118 L284.5,489.31118 L284.5,489.31118"
                  id="mbottom3-dash"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"
                  transform="translate(250.500000, 439.811180) scale(-1, 1) rotate(-270.000000) translate(-250.500000, -439.811180) "></path>
                <path
                  d="M215.15559,322.15559 L216.064931,322.15559 C233.185758,322.15559 247.064931,308.276417 247.064931,291.15559 L247.064931,252.15559 C247.064931,236.139332 260.048673,223.15559 276.064931,223.15559 L285.84441,223.15559 L285.84441,223.15559"
                  id="mbottom2-dash"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"
                  transform="translate(250.500000, 272.655590) scale(-1, 1) rotate(-270.000000) translate(-250.500000, -272.655590) "></path>
                <path
                  d="M63.5,131.5 L87.5674301,131.5 C104.135973,131.5 117.56743,118.068542 117.56743,101.5 L117.56743,77.5 C117.56743,60.9314575 130.998888,47.5 147.56743,47.5 L170.5,47.5 L170.5,47.5"
                  id="mtop1-dash"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"
                  transform="translate(117.000000, 89.500000) scale(-1, 1) rotate(-270.000000) translate(-117.000000, -89.500000) "></path>
                <path
                  d="M204.5,131.5 L228.56743,131.5 C245.135973,131.5 258.56743,118.068542 258.56743,101.5 L258.56743,77.5 C258.56743,60.9314575 271.998888,47.5 288.56743,47.5 L311.5,47.5 L311.5,47.5"
                  id="mbottom1-dash"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"
                  transform="translate(258.000000, 89.500000) scale(-1, -1) rotate(-90.000000) translate(-258.000000, -89.500000) "></path>
                <path
                  d="M51.2186648,152.781335 L93.08114,152.781335 C99.6508716,152.781335 105.671949,156.445913 108.690097,162.281335 C111.708245,168.116758 117.729323,171.781335 124.299054,171.781335 L303.781335,171.781335 L303.781335,171.781335"
                  id="mmid1-dash"
                  stroke="#F94627"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"
                  transform="translate(177.500000, 162.281335) scale(-1, 1) rotate(-270.000000) translate(-177.500000, -162.281335) "></path>
                <line
                  x1="74.5"
                  y1="237.220521"
                  x2="74.5"
                  y2="474.220521"
                  id="mtop2-dash"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"
                  transform="translate(74.500000, 355.720521) scale(-1, 1) translate(-74.500000, -355.720521) "></line>
                <g
                  id="mstop"
                  transform="translate(186.500000, 303.311180) rotate(-270.000000) translate(-186.500000, -303.311180) translate(177.500000, 294.311180)"
                  stroke="#FFFFFF">
                  <ellipse
                    id="Oval"
                    fill="#F94627"
                    cx="9.19992664"
                    cy="9"
                    rx="8.47058824"
                    ry="8.5"></ellipse>
                  <g
                    id="Group-2"
                    transform="translate(6.023456, 5.500000)"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <line
                      x1="1.40436682e-13"
                      y1="1.5158245e-13"
                      x2="6.1062244"
                      y2="6.59084539"
                      id="Path-2"></line>
                    <line
                      x1="1.40436682e-13"
                      y1="1.5158245e-13"
                      x2="6.1062244"
                      y2="6.59084539"
                      id="Path-2-Copy"
                      transform="translate(3.053112, 3.295423) scale(-1, 1) translate(-3.053112, -3.295423) "></line>
                  </g>
                </g>
                <g
                  id="catalog-post"
                  transform="translate(149.000000, 172.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    fill="#F94627"
                    x="-0.5"
                    y="-0.5"
                    width="87.2142857"
                    height="18"
                    rx="8.5"></rect>
                  <text
                    id="/login-POST"
                    fontFamily="DM Sans"
                    fontSize="10.5"
                    fontWeight="600"
                    line-spacing="13"
                    fill="#FFFFFF">
                    <tspan x="7.28571429" y="12.6071429">
                      /catalog POST
                    </tspan>
                  </text>
                </g>
                <g
                  id="mlogin-post"
                  transform="translate(38.000000, 110.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    fill="#5074FF"
                    x="-0.5"
                    y="-0.5"
                    width="76.2857143"
                    height="18"
                    rx="8.5"></rect>
                  <text
                    id="/login-POST"
                    fontFamily="DM Sans"
                    fontSize="10"
                    fontWeight="600"
                    line-spacing="13"
                    fill="#FFFFFF">
                    <tspan x="8.28571429" y="11.6071429">
                      /login POST
                    </tspan>
                  </text>
                </g>
                <g
                  id="mcheckout-post"
                  transform="translate(253.000000, 110.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    fill="#5074FF"
                    x="-0.5"
                    y="-0.5"
                    width="95.7142857"
                    height="18"
                    rx="8.5"></rect>
                  <text
                    id="/checkout-POST"
                    fontFamily="DM Sans"
                    fontSize="10"
                    fontWeight="600"
                    line-spacing="13"
                    fill="#FFFFFF">
                    <tspan x="9.85714286" y="11.6071429">
                      /checkout POST
                    </tspan>
                  </text>
                </g>
                <g
                  id="mcurrency-post"
                  transform="translate(254.000000, 260.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    fill="#5074FF"
                    x="-0.5"
                    y="-0.5"
                    width="93.2857143"
                    height="18"
                    rx="8.5"></rect>
                  <text
                    id="/login-POST"
                    fontFamily="DM Sans"
                    fontSize="10"
                    fontWeight="600"
                    line-spacing="13"
                    fill="#FFFFFF">
                    <tspan x="7.28571429" y="11.6071429">
                      /currency POST
                    </tspan>
                  </text>
                </g>
                <g
                  id="madservice-get"
                  transform="translate(256.000000, 447.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    fill="#5074FF"
                    x="-0.5"
                    y="-0.5"
                    width="90.8571429"
                    height="18"
                    rx="8.5"></rect>
                  <text
                    id="/login-POST"
                    fontFamily="DM Sans"
                    fontSize="10"
                    fontWeight="600"
                    line-spacing="13"
                    fill="#FFFFFF">
                    <tspan x="7.28571429" y="11.6071429">
                      /adservice GET
                    </tspan>
                  </text>
                </g>
                <g
                  id="mreviews-post"
                  transform="translate(32.000000, 339.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    fill="#5074FF"
                    x="-0.5"
                    y="-0.5"
                    width="88.4285714"
                    height="18"
                    rx="8.5"></rect>
                  <text
                    id="/login-POST"
                    fontFamily="DM Sans"
                    fontSize="10"
                    fontWeight="600"
                    line-spacing="13"
                    fill="#FFFFFF">
                    <tspan x="7.28571429" y="11.6071429">
                      /reviews POST
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
      <div className={styles.anim__desktop}>
        <img
          id="img"
          className={styles.anim__img}
          src="/img/products/voltmesh-azt-graph-app-firewall.png"
          alt="Zero-trust Voltmesh WAF graph"
        />
        <svg
          className={styles.anim__svg}
          width="540px"
          height="251px"
          viewBox="0 0 540 251"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg">
          <g
            id="Page-1"
            stroke="none"
            strokeWidth="1"
            fill="none"
            fillRule="evenodd">
            <g id="Artboard" transform="translate(-61.000000, -465.000000)">
              <g
                id="Group-15-Copy"
                transform="translate(61.000000, 465.000000)">
                <rect
                  id="Rectangle"
                  x="0"
                  y="0"
                  width="540"
                  height="251"></rect>
                <path
                  d="M378,122.551687 L381,122.551687 C398.120827,122.551687 412,108.672515 412,91.5516873 L412,75.2777778 C412,59.26152 424.983742,46.2777778 441,46.2777778 L446,46.2777778 L446,46.2777778"
                  id="bottom4"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="120"></path>
                <path
                  d="M378,133.277778 L382.63939,133.277778 C398.655648,133.277778 411.63939,146.26152 411.63939,162.277778 L411.63939,177.277778 C411.63939,193.84632 425.070848,207.277778 441.63939,207.277778 L446,207.277778 L446,207.277778"
                  id="bottom3"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="122"></path>
                <path
                  d="M229.5,207.277778 L230.40934,207.277778 C247.530168,207.277778 261.40934,193.398605 261.40934,176.277778 L261.40934,162.277778 C261.40934,146.26152 274.393083,133.277778 290.40934,133.277778 L300.18882,133.277778 L300.18882,133.277778"
                  id="bottom2"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="117"></path>
                <path
                  d="M48.1888196,102 L72.2562497,102 C88.8247922,102 102.25625,88.5685425 102.25625,72 L102.25625,69 C102.25625,52.4314575 115.687707,39 132.25625,39 L155.18882,39 L155.18882,39"
                  id="top1"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="145"></path>
                <path
                  d="M48.1888196,207.277778 L72.2562497,207.277778 C88.8247922,207.277778 102.25625,193.84632 102.25625,177.277778 L102.25625,174.277778 C102.25625,157.709235 115.687707,144.277778 132.25625,144.277778 L155.18882,144.277778 L155.18882,144.277778"
                  id="bottom1"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="145"
                  transform="translate(101.688820, 175.777778) scale(1, -1) translate(-101.688820, -175.777778) "></path>
                <path
                  d="M48.1888196,109 L92.1943979,109 C97.5554194,109 102.580227,111.612062 105.660252,116 C108.740277,120.387938 113.765085,123 119.126106,123 L286.362447,123 L286.362447,123"
                  id="mid1"
                  stroke="#F94627"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="240"></path>
                <g
                  id="stop"
                  transform="translate(289.000000, 116.000000)"
                  stroke="#FFFFFF">
                  <circle
                    id="Oval"
                    fill="#F94627"
                    cx="6.5"
                    cy="6.5"
                    r="6.5"></circle>
                  <g
                    id="Group-2"
                    transform="translate(3.800000, 3.900000)"
                    strokeLinecap="round"
                    strokeLinejoin="round">
                    <line
                      x1="1.20002767e-13"
                      y1="1.20002767e-13"
                      x2="5.21775232"
                      y2="5.21775232"
                      id="Path-2"></line>
                    <line
                      x1="1.20002767e-13"
                      y1="1.20002767e-13"
                      x2="5.21775232"
                      y2="5.21775232"
                      id="Path-2-Copy"
                      transform="translate(2.608876, 2.608876) scale(-1, 1) translate(-2.608876, -2.608876) "></line>
                  </g>
                </g>
                <line
                  x1="229.40934"
                  y1="39"
                  x2="446"
                  y2="39"
                  id="top2"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0.800000012"
                  strokeLinecap="round"
                  strokeDasharray="217"></line>
                <path
                  d="M378,122.551687 L381,122.551687 C398.120827,122.551687 412,108.672515 412,91.5516873 L412,75.2777778 C412,59.26152 424.983742,46.2777778 441,46.2777778 L446,46.2777778 L446,46.2777778"
                  id="bottom4-dash"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"></path>
                <path
                  d="M378,133.277778 L382.63939,133.277778 C398.655648,133.277778 411.63939,146.26152 411.63939,162.277778 L411.63939,177.277778 C411.63939,193.84632 425.070848,207.277778 441.63939,207.277778 L446,207.277778 L446,207.277778"
                  id="bottom3-dash"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"></path>
                <path
                  d="M229.5,207.277778 L230.40934,207.277778 C247.530168,207.277778 261.40934,193.398605 261.40934,176.277778 L261.40934,162.277778 C261.40934,146.26152 274.393083,133.277778 290.40934,133.277778 L300.18882,133.277778 L300.18882,133.277778"
                  id="bottom2-dash"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"></path>
                <path
                  d="M48.1888196,102 L72.2562497,102 C88.8247922,102 102.25625,88.5685425 102.25625,72 L102.25625,69 C102.25625,52.4314575 115.687707,39 132.25625,39 L155.18882,39 L155.18882,39"
                  id="top1-dash"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"></path>
                <path
                  d="M48.1888196,207.277778 L72.2562497,207.277778 C88.8247922,207.277778 102.25625,193.84632 102.25625,177.277778 L102.25625,174.277778 C102.25625,157.709235 115.687707,144.277778 132.25625,144.277778 L155.18882,144.277778 L155.18882,144.277778"
                  id="bottom1-dash"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"
                  transform="translate(101.688820, 175.777778) scale(1, -1) translate(-101.688820, -175.777778) "></path>
                <path
                  d="M48.1888196,109 L92.1943979,109 C97.5554194,109 102.580227,111.612062 105.660252,116 C108.740277,120.387938 113.765085,123 119.126106,123 L286.362447,123 L286.362447,123"
                  id="mid1-dash"
                  stroke="#F94627"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"></path>
                <line
                  x1="229.40934"
                  y1="39"
                  x2="446"
                  y2="39"
                  id="top2-dash"
                  stroke="#6783F1"
                  strokeWidth="4.5"
                  opacity="0"
                  strokeLinecap="round"
                  strokeDasharray="0,7"></line>
                <g
                  id="catalog-post"
                  transform="translate(178.000000, 115.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    fill="#F94627"
                    x="-0.5"
                    y="-0.5"
                    width="87.2142857"
                    height="18"
                    rx="8.5"></rect>
                  <text
                    id="/login-POST"
                    fontFamily="DM Sans"
                    fontSize="10.5"
                    fontWeight="600"
                    line-spacing="13"
                    fill="#FFFFFF">
                    <tspan x="7.28571429" y="12.6071429">
                      /catalog POST
                    </tspan>
                  </text>
                </g>
                <g id="login-post" transform="translate(74.000000, 62.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    fill="#5074FF"
                    x="-0.5"
                    y="-0.5"
                    width="76.2857143"
                    height="18"
                    rx="8.5"></rect>
                  <text
                    id="/login-POST"
                    fontFamily="DM Sans"
                    fontSize="10"
                    fontWeight="600"
                    line-spacing="13"
                    fill="#FFFFFF">
                    <tspan x="8.28571429" y="11.6071429">
                      /login POST
                    </tspan>
                  </text>
                </g>
                <g
                  id="checkout-post"
                  transform="translate(55.000000, 170.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    fill="#5074FF"
                    x="-0.5"
                    y="-0.5"
                    width="95.7142857"
                    height="18"
                    rx="8.5"></rect>
                  <text
                    id="/checkout-POST"
                    fontFamily="DM Sans"
                    fontSize="10"
                    fontWeight="600"
                    line-spacing="13"
                    fill="#FFFFFF">
                    <tspan x="9.85714286" y="11.6071429">
                      /checkout POST
                    </tspan>
                  </text>
                </g>
                <g
                  id="currency-post"
                  transform="translate(230.000000, 170.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    fill="#5074FF"
                    x="-0.5"
                    y="-0.5"
                    width="93.2857143"
                    height="18"
                    rx="8.5"></rect>
                  <text
                    id="/login-POST"
                    fontFamily="DM Sans"
                    fontSize="10"
                    fontWeight="600"
                    line-spacing="13"
                    fill="#FFFFFF">
                    <tspan x="7.28571429" y="11.6071429">
                      /currency POST
                    </tspan>
                  </text>
                </g>
                <g
                  id="adservice-get"
                  transform="translate(352.000000, 170.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    fill="#5074FF"
                    x="-0.5"
                    y="-0.5"
                    width="90.8571429"
                    height="18"
                    rx="8.5"></rect>
                  <text
                    id="/login-POST"
                    fontFamily="DM Sans"
                    fontSize="10"
                    fontWeight="600"
                    line-spacing="13"
                    fill="#FFFFFF">
                    <tspan x="7.28571429" y="11.6071429">
                      /adservice GET
                    </tspan>
                  </text>
                </g>
                <g
                  id="reviews-post"
                  transform="translate(354.000000, 62.000000)">
                  <rect
                    id="Rectangle"
                    stroke="#FFFFFF"
                    fill="#5074FF"
                    x="-0.5"
                    y="-0.5"
                    width="88.4285714"
                    height="18"
                    rx="8.5"></rect>
                  <text
                    id="/login-POST"
                    fontFamily="DM Sans"
                    fontSize="10"
                    fontWeight="600"
                    line-spacing="13"
                    fill="#FFFFFF">
                    <tspan x="7.28571429" y="11.6071429">
                      /reviews POST
                    </tspan>
                  </text>
                </g>
              </g>
            </g>
          </g>
        </svg>
      </div>
    </div>
  )
}

export default ZeroTrustWafGraphAnim
