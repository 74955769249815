import React, { useState, useEffect } from "react"
import styles from "./cascade-video.module.scss"

const CascadeVideo = ({ props }) => {
  const totalVideos = props.videos.length

  const [componentID, setComponentID] = useState(0)

  const [activeTabIndex, setActiveTabIndex] = useState(0)

  const [currentVideoUrl, setCurrentVideoUrl] = useState(
    props.videos[activeTabIndex].videoUrl
  )

  const [videoStart, setVideoStart] = useState(false)

  //-- Changing active video tab + video
  const changeCurrentTab = () => {
    setCurrentVideoUrl(props.videos[activeTabIndex].videoUrl)
  }

  //-- Add active class to tab only when video starts
  const videoStarted = () => {
    setVideoStart(true)
  }

  //-- Change active video tab when video ends
  const videoEnded = () => {
    setVideoStart(false)
    setActiveTabIndex(activeTabIndex =>
      activeTabIndex + 1 === totalVideos ? 0 : activeTabIndex + 1
    )

    //-- Reinit component if there's only one video
    if (totalVideos === 1) {
      setComponentID(componentID => componentID + 1)
    }
  }

  useEffect(() => {
    changeCurrentTab(activeTabIndex)
  }, [activeTabIndex])

  return (
    <div key={componentID} className={styles.cascade}>
      <div className={styles.cascade__item}>
        {props.videos.map(
          (video, i) =>
            i === activeTabIndex && (
              <video
                key={i}
                autoPlay
                playsInline={true}
                muted={true}
                width="640"
                height="360"
                onPlay={videoStarted}
                onEnded={videoEnded}>
                <source src={video.videoUrl} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            )
        )}
      </div>
      <div className="d-flex">
        {props.videos.map((video, i) => (
          <button
            key={i}
            data-id={i}
            // className={`flex-fill text-center ${styles.cascade__tab} ${styles.active}`}
            className={
              activeTabIndex === i && videoStart
                ? `flex-fill text-center ${styles.cascade__tab} ${styles.cascade__tabActive}`
                : `flex-fill text-center ${styles.cascade__tab}`
            }
            onClick={event => setActiveTabIndex(i)}>
            <span>{video.title}</span>
            <div className={"progress " + styles.cascade__progress}>
              <div
                className={"progress-bar " + styles.cascade__bar}
                style={{ animationDuration: video.videoLength + "ms" }}></div>
            </div>
          </button>
        ))}
      </div>
    </div>
  )
}

export default CascadeVideo
