import styles from "./comparison-table.module.scss"
import React from "react"

const ComparisonTable = ({ props }) => {
  const { rows } = props

  const getPicto = item => {
    if (item === "Included") {
      return (
        <div
          className={`${styles.comparison__icon} ${styles.comparison__iconIncluded}`}></div>
      )
    } else if (item === "Included+") {
      return (
        <div
          className={`${styles.comparison__icon} ${styles.comparison__iconIncluded} ${styles.comparison__iconIncludedFull}`}></div>
      )
    } else if (item === "-") {
      return (
        <div
          className={`${styles.comparison__icon} ${styles.comparison__iconNotIncluded}`}></div>
      )
    }
    return item
  }

  return (
    <div className={styles.comparison}>
      <div className={styles.comparison__head}>
        <div className={styles.comparison__title}>{props.cols[0]}</div>
        {props.cols.map(
          (col, i) =>
            i > 0 && (
              <div className={styles.comparison__col} key={`col${i}`}>
                {col}
              </div>
            )
        )}
      </div>
      <div className={styles.comparison__body}>
        {rows.map((row, i) => (
          <div className={styles.comparison__row} key={`row${i}`}>
            <div className={styles.comparison__title}>{row.name}</div>
            {row.cells.map((cell, i) => {
              return (
                <div className={styles.comparison__col} key={i}>
                  {getPicto(cell)}
                </div>
              )
            })}
          </div>
        ))}
      </div>
    </div>
  )
}

export default ComparisonTable
