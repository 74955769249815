import styles from "./products-use-cases.module.scss"
import React, { Component } from "react"
import { Link } from "gatsby"
import ArrowSvg from "../svg/arrow"

//-- Use Cases section container
export class ProductsUseCases extends Component {
  render() {
    const { children } = this.props
    let mainClass = styles.usecases
    //-- Simple styling
    if (this.props.simple) {
      mainClass += " " + styles.usecases__simple
    }
    //-- Inline Icons
    else if (this.props.inlineIcons) {
      mainClass += " " + styles.usecases__inlineicons
    }
    //-- Without cards styling
    if (this.props.noCards) {
      mainClass += " " + styles.usecases__nocards
    }
    //-- 3 per row
    if (this.props.threePerRow) {
      mainClass += " " + styles.usecases__threeperrow
    }
    return (
      <div className={mainClass}>
        {children.map((el, i) => (
          <div className={styles.usecases__item} key={el.props.title}>
            <img
              className={styles.usecases__icon}
              src={el.props.imgurl}
              alt={el.props.imgalt}
            />
            <h3 className={styles.usecases__head}>{el.props.title}</h3>
            {el.props.children}
            {el.props.link && (
              <div className={styles.usecases__cta}>
                <Link className={styles.usecases__link} to={el.props.link}>
                  Learn More{" "}
                  <span className="ml-2">
                    <ArrowSvg />
                  </span>
                </Link>
              </div>
            )}
          </div>
        ))}
      </div>
    )
  }
}
