import React from "react"
import cblockStyles from "../../styles/commons/cblock.module.scss"
import { ProductsUseCases } from "../products-use-cases/products-use-cases"

const UseCaseBenefits = ({ props }) => {
  const { content } = props

  return (
    <section
      className={`${cblockStyles.cblock} ${props.noPadding ? "py-0" : ""}`}>
      <div className={`${props.noPadding ? "" : "container"}`}>
        {!props.noTitle && (
          <div className={cblockStyles.cblock__header}>
            <h2>{props.title || "Business Benefits"}</h2>
          </div>
        )}

        <ProductsUseCases simple="true" noCards={props.noCards}>
          {content.benefits.map(benefit => (
            <div
              key={benefit.title}
              title={benefit.title}
              imgurl={`${props.imgUrl || "/img/solutions/"}${benefit.iconName}`}
              imgalt="Benefit icon">
              <p>{benefit.description}</p>
            </div>
          ))}
        </ProductsUseCases>
      </div>
    </section>
  )
}

export default UseCaseBenefits
